import React from 'react';
import PropTypes from 'prop-types';

function NewTabLink({ children, link }) {
    return (
        <a href={link} target="_blank" rel="noopener noreferrer">
            {children}
        </a>
    );
}

NewTabLink.propTypes = {
    link: PropTypes.string.isRequired,
};

export default NewTabLink;
