import React, { Component } from 'react';
import showdown from 'showdown';
import styled from 'styled-components';

const Holder = styled.div`
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
`;

const converter = new showdown.Converter({simpleLineBreaks: true});

class MarkdownConverter extends Component {
    render() {
        return (
          <Holder className="markdown-holder" dangerouslySetInnerHTML={{ __html: converter.makeHtml(this.props.content)}}/>
        )
    }
}

export default MarkdownConverter;
