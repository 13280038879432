import React from 'react';
import styled from 'styled-components';

const Holder = styled.div`
  padding-top: 3rem;
  overflow: hidden;
`;

function PageHolder({children}) {
    return (
      <Holder>
        {children}
      </Holder>
    )
}

export default PageHolder;
