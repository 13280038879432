import React from 'react';
import {graphql, Link} from 'gatsby';
import MarkdownConverter from '../components/atoms/MarkdownConverter';
import styled from 'styled-components';
import PageHolder from '../components/atoms/PageHolder';
import NewTabLink from '../components/atoms/NewTabLink';
import Seo from '../components/molecules/Seo';
import BackArrow from "../assets/back-arrow.inline.svg";

const Holder = styled.div`
    color: ${(props) => props.theme.colours.black};
    background-color: ${(props) => props.theme.colours.white};
    min-height: 100vh;
`;

const Inner = styled.div`
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    @media (${(props) => props.theme.breakpoints.md}) {
        grid-template-columns: 1fr 1fr;
    }
`;

const ContactDetails = styled.div`
    > :first-child {
        margin-top: 0;
    }
    > :last-child {
        margin-bottom: 0;
    }
`;

const BackLink = styled.div`
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 10;
  mix-blend-mode: difference;
  svg {
    height: 0.8rem;
    width: auto;
    display: block;
  }
`;

const Page = ({ data }) => (
    <Holder>
        <Seo title={data.contentfulPage.title} />
        <BackLink>
            <Link to="/" title="Back to home"><BackArrow/></Link>
        </BackLink>
        <PageHolder>
            <Inner>
                <MarkdownConverter content={data.contentfulPage.text.text} />
                <ContactDetails>
                    <h2>
                        Contact
                        <br />
                        <a href="mailto:henry_wolff@outlook.com">
                            henry_wolff@outlook.com
                        </a>
                    </h2>
                    <h2>
                        About
                        <br />
                        <Link to="/curriculum-vitae">Curriculum Vitae</Link>
                    </h2>
                    <h2>
                        Social
                        <br />
                        <NewTabLink link="https://www.instagram.com/henry___wolff">
                            Instagram
                        </NewTabLink>
                    </h2>
                    <h2>
                        Website design
                        <br />
                        <NewTabLink link="https://newassoc.world/">
                            New Association
                        </NewTabLink>
                    </h2>
                    <h2>
                        Website development
                        <br />
                        <NewTabLink link="https://wills-websites.com/">
                            Will’s Websites
                        </NewTabLink>
                    </h2>
                </ContactDetails>
            </Inner>
        </PageHolder>
    </Holder>
);

export default Page;

export const pageQuery = graphql`
    query($id: String!) {
        contentfulPage(id: { eq: $id }) {
            title
            text {
                text
            }
        }
    }
`;
